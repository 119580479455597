import Component from '@ember/component';
import { computed } from '@ember/object';
import { equal, filterBy, readOnly } from '@ember/object/computed';
import { PropTypes as T } from 'ember-prop-types';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import locales from 'gigshq/constants/locales';
import genders from 'gigshq/constants/genders';
import DEFAULT_MEMBERSHIP_PLANS from 'gigshq/constants/membership-plans';
import { USER_TYPE_ARTIST, USER_TYPE_TENANT, USER_TYPE_MEMBER } from 'gigshq/constants/user-types';
import { inject as service } from '@ember/service';
import { notEmpty } from '@ember/object/computed';
import config from 'gigshq/config/environment';

export default Component.extend(Localizable(translations), {
  propTypes: {
    userType: T.oneOf([USER_TYPE_ARTIST, USER_TYPE_TENANT, USER_TYPE_MEMBER]).isRequired,
    isSigningUp: T.bool.isRequired,
    isSignedUp: T.bool.isRequired,
    errors: T.nullable(T.array).isRequired,
    doSignUp: T.func.isRequired,
    onSignUpSuccess: T.func.isRequired
  },

  localeManager: service('managers/locale-manager'),
  stripe: service('stripev3'),
  stripeElement: null,
  stripeError: null,

  hasErrors: notEmpty('errors'),

  getDefaultProps() {
    return {
      userType: USER_TYPE_MEMBER
    };
  },

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),

  availableLocales: locales,
  genderOptions: genders,

  membershipPlans: computed(function() {
    if (config.environment === 'production')
      return [...DEFAULT_MEMBERSHIP_PLANS.prdPlans];
    else
      return [...DEFAULT_MEMBERSHIP_PLANS.devPlans];
  }),

  visibleMembershipPlans: filterBy('membershipPlans', 'visible', true),

  hideStripeCardFields: computed('stripeProductId', function() {
    const subscription = this.get('stripeProductId');
    const isFreeSubscription = this.membershipPlans
      .find((plan) => plan.stripeProductId === subscription)?.key === 'free';
    return isFreeSubscription;
  }),

  currLocale: readOnly('localeManager.locale'),

  isUserTypeMember: equal('userType', USER_TYPE_MEMBER),

  didReceiveAttrs() {
    if (this.isSignedUp)
      this.onSignUpSuccess();
    else {
      if (this.locale == null)
        this.locale = this.currLocale;

      window.scrollTo(0, 0);
    }
  },

  _doActualSignup() {
    this.doSignUp(
      this.getProperties(
        'userType',
        'firstName',
        'lastName',
        'birthdate',
        'gender',
        'contactNumber',
        'email',
        'password',
        'passwordConfirmation',
        'locale',
        'address',
        'city',
        'province',
        'postalCode',
        'country',
        'usedPromoCode',
        'stripeProductId',
        'stripeCardToken'
      )
    );
  },

  actions: {
    setStripeElement(stripeElement) {
      this.set('stripeElement', stripeElement);
    },

    setFormValue(name, value) {
      this.set(name, value);
    },

    selectGender(gender) {
      this.set('gender', gender.key);
    },

    selectMembershipPlan(plan) {
      this.set('stripeProductId', plan.stripeProductId);
    },

    selectLocale(locale) {
      this.set('locale', locale.key);
    },

    submit() {
      const isFreeSubscription = this.hideStripeCardFields;
      if (this.userType === USER_TYPE_MEMBER && !isFreeSubscription) {
        // if member is signing, tokenize the credit card first before proceeding with signup
        this.get('stripe')
          .createToken(this.get('stripeElement'))
          .then((result) => {
            if (result.error) {
              this.set('stripeError', result.error.code);
            } else if (result.token && result.token.id) {
              this.set('stripeError', null);
              this.set('stripeCardToken', result.token.id);
              this._doActualSignup();
            }
          });
      } else
        this._doActualSignup();

    },
  }
});
