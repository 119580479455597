import { not, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import ErrorFormatter from 'gigshq/utils/error-formatter';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import offerTypes from 'gigshq/constants/offer-types';

export default Component.extend(Localizable(translations), {
  tagName: 'form',

  characterCountLimit: 140,
  weekdays: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],

  offerTypes: computed(function() {
    return offerTypes.map(offerType => {
      return {
        groupName: `$${offerType.groupName} ${this.localTranslation('offer_type.discount')}`,
        options: offerType.options,
      }
    });
  }),

  redemptionPeriodOptions: [ 'daily', 'weekly' ],

  initialPausedValue: null,

  didReceiveAttrs() {
    if (this.initialPausedValue == null)
      this.initialPausedValue = this.paused;
  },

  hasVenue: notEmpty('venue.id'),

  selectedOfferType: computed('offerType', function() {
    return this.offerTypes
      .map(offerType => offerType.options)
      .flat(1)
      .find(offerType => offerType.value === this.offerType);
  }),

  offerActive: not('paused'),

  offerActiveIcon: computed('paused', function() {
    return this.paused ? 'pause-circle' : 'check-circle';
  }),

  offerActiveColour: computed('paused', function() {
    return this.paused ? 'bg-red' : 'bg-green';
  }),

  offerActiveLegend: computed('paused', function() {
    return this.paused ? 'status.paused' : 'status.active';
  }),

  offerImageUrl: computed('offerType', function() {
    if (this.offerType == null || this.offerType == '')
      return null;

    if (this.get('i18n.locale') === 'en') {
      if (this.offerType === '10-for-20')
        return `/assets/images/venue-offers/10-for-20-en.png`;
      else if (this.offerType === '10-for-25')
        return `/assets/images/venue-offers/10-for-25-en.png`;
      else if (this.offerType === '10-for-30')
        return `/assets/images/venue-offers/10-for-30-en.png`;
      else if (this.offerType === '10-for-40')
        return `/assets/images/venue-offers/10-for-40-en.jpg`;
      else if (this.offerType === '10-for-50')
        return `/assets/images/venue-offers/10-for-50-en.jpg`;
      else if (this.offerType === '15-for-30')
        return `/assets/images/venue-offers/15-for-30-en.png`;
      else if (this.offerType === '15-for-40')
        return `/assets/images/venue-offers/15-for-40-en.png`;
      else if (this.offerType === '15-for-45')
        return `/assets/images/venue-offers/15-for-45-en.png`;
      else if (this.offerType === '15-for-50')
        return `/assets/images/venue-offers/15-for-50-en.png`;
      else if (this.offerType === '15-for-60')
        return `/assets/images/venue-offers/15-for-60-en.png`;
      else if (this.offerType === '20-for-40')
        return `/assets/images/venue-offers/20-for-40-en.png`;
      else if (this.offerType === '20-for-50')
        return `/assets/images/venue-offers/20-for-50-en.png`;
      else if (this.offerType === '20-for-60')
        return `/assets/images/venue-offers/20-for-60-en.png`;
    } else if (this.get('i18n.locale') === 'fr') {
      if (this.offerType === '10-for-20')
        return `/assets/images/venue-offers/10-for-20-fr.png`;
      else if (this.offerType === '10-for-25')
        return `/assets/images/venue-offers/10-for-25-fr.png`;
      else if (this.offerType === '10-for-30')
        return `/assets/images/venue-offers/10-for-30-fr.png`;
      else if (this.offerType === '10-for-40')
        return `/assets/images/venue-offers/10-for-40-fr.jpg`;
      else if (this.offerType === '10-for-50')
        return `/assets/images/venue-offers/10-for-50-fr.jpg`;
      else if (this.offerType === '15-for-30')
        return `/assets/images/venue-offers/15-for-30-fr.png`;
      else if (this.offerType === '15-for-40')
        return `/assets/images/venue-offers/15-for-40-fr.png`;
      else if (this.offerType === '15-for-45')
        return `/assets/images/venue-offers/15-for-45-fr.png`;
      else if (this.offerType === '15-for-50')
        return `/assets/images/venue-offers/15-for-50-fr.png`;
      else if (this.offerType === '15-for-60')
        return `/assets/images/venue-offers/15-for-60-fr.png`;
      else if (this.offerType === '20-for-40')
        return `/assets/images/venue-offers/20-for-40-fr.png`;
      else if (this.offerType === '20-for-50')
        return `/assets/images/venue-offers/20-for-50-fr.png`;
      else if (this.offerType === '20-for-60')
        return `/assets/images/venue-offers/20-for-60-fr.png`;
    }
  }),

  showMaxedActiveOfferNote: computed('initialPausedValue', 'venue.maxedActiveOffers', function() {
    return this.venue.maxedActiveOffers && this.initialPausedValue;
  }),

  actions: {
    toggleOffer(checked) {
      this.setProperty('paused', !checked);
    },

    toggleWeekday(value, checked) {
      console.log(this.blackoutDays);

      if (this.checked)
        this.blackoutDays.push(value);
      else {
        const index = this.blackoutDays.indexOf(value);
        this.blackoutDays.splice(index, 1);
      }

      this.setProperty('blackoutDays', this.blackoutDays);
      console.log('toggleWeekday', value, checked);
    },

    didCreateImage(image) {
      this.setOfferImage(image);
      this.setProperty('image', {
        id: image.id,
        imageUrl: image.thumbnailImageUrl
      });
    },
  },

  baseErrors: computed('errors.[]', function() {
    return ErrorFormatter.byPath('base', this.errors);
  }),

  errorsByInputName: computed('errors.[]', function() {
    return ErrorFormatter.byInputName(this.errors);
  }),
});
